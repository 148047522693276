.clearfix:before,
.clearfix:after,
#error-page .error-page-content:before,
#error-page .error-page-content:after {
  content: " ";
  display: table;
}
.clearfix:after,
#error-page .error-page-content:after {
  clear: both;
}
.clearfix:before,
.clearfix:after,
#error-page .error-page-content:before,
#error-page .error-page-content:after {
  content: " ";
  display: table;
  line-height: 0;
}
.clearfix:after,
#error-page .error-page-content:after {
  clear: both;
}
/*
 *  Responsive Utilities
 */
@media (max-width: 767px) {
  #body-main,
  .body-content {
    min-height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #body-main,
  .body-content {
    min-height: 500px;
  }
}
#error-page .error-page-content {
  margin-top: 100px;
  margin-left: -21px;
  margin-right: -21px;
}
@media (max-width: 767px) {
  #error-page .error-page-content {
    margin-top: 20px;
  }
}
.error-column {
  float: left;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 21px;
  padding-right: 21px;
}
@media (min-width: 768px) {
  .error-column {
    float: left;
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .error-column {
    float: left;
    width: 50%;
  }
}
@media (min-width: 1381px) {
  .error-column {
    float: left;
    width: 50%;
  }
}
.error-column img {
  margin: 35px 0 0 0;
}
.error-column:first-child {
  text-align: center;
}
.error-column:first-child div {
  font-family: "Lato", Tahoma;
  font-size: 72px;
  font-weight: normal;
  line-height: 1em;
  text-transform: uppercase;
}
.error-column:first-child span {
  display: block;
  font-family: "Lato", Tahoma;
  font-size: 36px;
  line-height: 1em;
  padding: 10px 0 30px 0;
}
