@import '../../../variables.less';
@import "../../../mixins/grid.less";
@import "../../../mixins/responsive_utilities.less";

#body-main,
.body-content {
    .onp-style(
        @small: {
            min-height: 500px;
        }
    );
}

#error-page .error-page-content {
    margin-top: 100px;

    .onp-row();

    .onp-style(
        @xs: {
            margin-top: 20px;
        }
    );
}


.error-column {
    .onp-column(@xs: 12, @sm: 6, @md: 6, @lg: 6);

    img {
        margin: 35px 0 0 0;
    }

    &:first-child {
        text-align: center;

        div {
            font-family: @headerFontFamily;
            font-size: 72px;
            font-weight: normal;
            line-height: 1em;
            text-transform: uppercase;
        }

        span {
            display: block;
            font-family: @cursiveHeaderFontFamily;
            font-size: 36px;
            line-height: 1em;
            padding: 10px 0 30px 0;
        }
    }
}
